import styles from "./HeroSection.module.css";

const HeroLoanInspection = () => {
  return (
    <section className={styles.heroSection}>
      <div className={styles.heroContent}>
        <div className={styles.heroBody}>
          <div className={styles.text}>Automate</div>
          <h1
            className={styles.text1}
          >{`Loan Inspection System `}</h1>
          <div
            className={styles.heroDescription}
          >{`Say goodbye to outdated paper and pen methods – digitize your inspections with our system and apps. Complete your inspections on-site, upload photos and videos instantly, and approve with digital signatures, eliminating the need for office rework.`}</div>
          
        </div>
          {/* <YoutubeEmbed embedId="XWN_SjxxddY" /> */}
          
          <video className={styles.video} controls controlslist="nodownload ">
              <source src= "/video.mp4" type="video/mp4" />
          </video>
        </div>
    </section>
  );
};

export default HeroLoanInspection;

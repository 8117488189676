import styles from "./Header.module.css";
import Anchorlink from "react-anchor-link-smooth-scroll";
import buttonstyles from "./Button.module.css";
import Hamburger from "./Hamburger";
import { useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  console.log(location);
  return (
    <>
      <div className={styles.header}>
        <div className={styles.navigation}>
          <a href="/" >
          <img
            className={styles.logoIcon}
            loading="lazy"
            alt=""
            src="/logo.svg"
          />
          </a>
          <div className={styles.headerMenuMain}>
            <div className={styles.links}>
              {
              location.pathname === "/" && <>
              <Anchorlink href={location.pathname === "/" ? "#our-work" : "#"} className={styles.text}>Our Work</Anchorlink>
              <Anchorlink href="#who-we-are" className={styles.text}>Who We are</Anchorlink>
              <Anchorlink href="#careers" className={styles.text}>Careers</Anchorlink>
              <Anchorlink href="#contact-us" className={styles.text}>Contact Us</Anchorlink>
              </>
              }
              
            </div>
            
          </div>
          {
              location.pathname === "/" ? <>
              <Anchorlink href="#contact-us" className={styles.text}>
          <div className={buttonstyles.button}>
           <span className={styles.text}>Contact Us</span> 
          </div>
          </Anchorlink>
          <div className={styles.mobilenav}>
            { <Hamburger />}
            </div>
            </>
            : <>
            <a href="/" className={styles.text}>
          <div className={buttonstyles.homebutton}>
           <span className={styles.text}>Back to Home</span> 
          </div>
          </a>
            </>
          }
          
          
        </div>
      </div>
    </>
  );
};

export default Header;

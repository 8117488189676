import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import Body from "../components/Body";
import Features from "../components/Features";
import ContactUs from "../components/ContactUs";
import ContactUsDetail from "../components/ContactUsDetail";
import Footer from "../components/Footer";
import styles from "./MultiplaiLandingPage.module.css";
import Career from "../components/Career";
import ScrollToTop from "react-scroll-to-top";

const MultiplaiLandingPage = () => {
  return (
    <div className={styles.multiplaiLandingPage}>
      <Header />
      <HeroSection />
      <Body />
      <Features />
      <Career/>
      <ContactUs />
      <ContactUsDetail />
      <Footer style={{ marginTop: "150vh" }}/>
      <ScrollToTop smooth color="white" width="20" height="20" className={styles.scroll}/>
    </div>
  );
};

export default MultiplaiLandingPage;

import BodySection1 from "./BodySection1";
import BodySection from "./BodySection";
import styles from "./Body.module.css";

const BodyLoanInspection = () => {
  return (
    <section id="our-work" className={styles.body}>
      <h1 className={styles.ourWork}>Loan Inspection System</h1>
      <BodySection1
      text="Beautifully crafted Dashboard"
      text1="Dashboard"
      text2="Effortlessly monitor inspection progress, total counts, recent activities, and yearly summaries with our sleek dashboard. Generate professional reports and conduct periodic on-field inspections seamlessly using our inspection software."
      image21="/inspection.png"
      hideLearnMore={true}
       />
      <BodySection
        text="Location based tracking"
        text1="Live Tracking"
        text2="Our inspection app simplifies location capture, photo addition, and status updates, ensuring seamless integration for efficient processes. With location-based locking, it promotes on-site inspections, enhancing accuracy and accountability. Experience streamlined efficiency and transparency in your inspection process with our app."
        image21="/Inspectionapp.png"
        hideLearnMore={true}
      />
      <BodySection1
        text="Schedule"
        text1="Quarterly Inspections"
        text2="After finalizing basic info, it's time for inspections, scheduled quarterly and specifying inspectors and verifiers, with the corresponding client displaying inspection status, whether open, ongoing, or completed."
        image21="/schedule.png"
        hideLearnMore={true}
      />
      <BodySection 
      text="Customer Information"
      text1="Property Details"
      text2="After conducting on-site inspections and entering all details including images, video, and stock information via our inspection app, users can seamlessly view and analyze the comprehensive data within our application."
      image21="/property-details.png"
      hideLearnMore={true}
      />
      <BodySection1
        text="Map"
        text1="Cluster Map View"
        text2="Easily access all client information through a cluster map view, where specific clients can be selected to outline boundaries, optionally color-coded, for area visualization on a Google Map. This feature enables access to details such as the nearest branch from the inspection area, inspection branch, distance, total area, and more."
        image21="/map-view.png"
        hideLearnMore={true}
      />
      <BodySection 
      text="Report"
      text1="Inspections Report"
      text2="After conducting on-site inspections and entering all details including images, video, and stock information via our inspection app, users can seamlessly view and analyze the comprehensive data within our application."
      image21="/property-details.png"
      hideLearnMore={true}
      />
    </section>
  );
};

export default BodyLoanInspection;

import BodySection1 from "./BodySection1";
import BodySection from "./BodySection";
import styles from "./Body.module.css";

const Body = () => {
  return (
    <section id="our-work" className={styles.body}>
      <h1 className={styles.ourWork}>Our Work</h1>
      <BodySection1
      text="Embrace location based tracking"
      text1="Loan Inspection System"
      text2="Our Loan Inspection System simplifies loan inspections with features like real-time updates and location-based locking, ensuring efficient, accountable, and transparent processes."
      image21="/inspection.png"
      // learnmorelink="/loan-inspection"
       />
      <BodySection
        text="Offline telecom services"
        text1="Green Recharge"
        text2="Our green recharge app simplifies mobile recharge and number management by offering key functionalities offline, including balance checks and number verification, ensuring convenience without internet dependency."
        image21="/green-recharge.png"
      />
      <BodySection1 
      text="conversational finbot"
      text1="AI Chatbot"
      text2="Experience seamless assistance across departments with our conversational AI chatbot, simplifying customer queries through text or voice interaction."
      image21="/chatbot.png"
      />
      <BodySection
        text="transform your technical hiring"
        text1="Interwiz"
        text2="Streamline and optimize your technical hiring. A platform to screen, interview and shortlist the best candidates all in one place through InterWiz."
        image21="/livecodepairing.png"
      />
    </section>
  );
};

export default Body;

import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from "./MultiplaiLandingPage.module.css";
import BodyLoanInspection from "../components/BodyLoanInspection";
import HeroLoanInspection from "../components/HeroLoanInspection";
import ContactUs from "../components/ContactUs";
import ContactUsDetail from "../components/ContactUsDetail";
import ScrollToTop from "react-scroll-to-top";  


const LoanInspectionSystem = () => {
  return (
    <div className={styles.multiplaiLandingPage}>
      <Header />
      <HeroLoanInspection />
      <BodyLoanInspection />
      <ContactUs />
      <ContactUsDetail />
      {/* <Footer style={{ marginTop: "150vh" }}/> */}
      <ScrollToTop smooth color="white" width="20" height="20" className={styles.scroll}/>
    </div>
  );
};

export default LoanInspectionSystem;
